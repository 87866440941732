import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import { SERVER_URL } from '@/env-vars'
import { useAuthFromStorage } from '@/hooks/useAuthStorage'
import { toast } from 'sonner'
import { errors } from '@/i18n/errors'

export function useRemoveVideo() {
	const { authToken } = useAuthFromStorage()

	return useMutation({
		mutationKey: ['remove-saved-video'],
		mutationFn: async (videoTitle: string) => {
			console.log('Inside useRemoveVideo. video title: ', videoTitle)
			const response = await axios.delete(
				`${SERVER_URL}/favorites/remove`,
				{
					headers: { Authorization: `Bearer ${authToken}` },
					params: { videoTitle },
				},
			)
			return response.data
		},
		onError: () => {
			toast.error(errors.REMOVE_VIDEO_ERROR)
		},
	})
}
