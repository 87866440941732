import { useEffect, useRef, useState } from 'react'

export const useChatSearch = (messages: any[]) => {
	const [searchQuery, setSearchQuery] = useState<string>('')
	const [searchResults, setSearchResults] = useState<number[]>([])
	const [currentMatchIndex, setCurrentMatchIndex] = useState<number | null>(null)
	const messageRefs = useRef<HTMLDivElement[]>([])

	// filter messages based on search query
	const handleSearch = () => {
		if (searchQuery.trim()) {
			const results = messages
				.map((message, index) =>
					message.type === 'text' && message.content.toLowerCase().includes(searchQuery.toLowerCase())
						? index
						: null
				)
				.filter((index) => index !== null) as number[]

			setSearchResults(results)
			setCurrentMatchIndex(results.length > 0 ? results.length - 1 : null) // set to the most recent message matched
		} else {
			setSearchResults([])
			setCurrentMatchIndex(null)
		}
	}

	const handleNextMatch = () => {
		if (searchResults.length > 0) {
			setCurrentMatchIndex((currentMatchIndex! + 1) % searchResults.length)
		}
	}

	const handlePreviousMatch = () => {
		if (searchResults.length > 0) {
			setCurrentMatchIndex((currentMatchIndex! - 1 + searchResults.length) % searchResults.length)
		}
	}

	useEffect(() => {
		if (currentMatchIndex !== null && searchResults.length > 0) {
			const matchIndex = searchResults[currentMatchIndex]
			const targetElement = messageRefs.current[matchIndex]
			targetElement?.scrollIntoView({ behavior: 'smooth', block: 'center' })
		}
	}, [currentMatchIndex, searchResults])

	return {
		searchQuery,
		setSearchQuery,
		searchResults,
		currentMatchIndex,
		setSearchResults,
		setCurrentMatchIndex,
		handleSearch,
		handleNextMatch,
		handlePreviousMatch,
		messageRefs,
	}
}
