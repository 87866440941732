import React from 'react'
import { ArrowLeft, ChevronDown, ChevronUp } from 'lucide-react'
import { Input } from '@/components/ui/input'
import { Button } from '@/components/ui/button'

interface ChatSearchBarProps {
	searchQuery: string
	setSearchQuery: (query: string) => void
	handleSearch: () => void
	handleNextMatch: () => void
	handlePreviousMatch: () => void
	closeSearchBar: () => void
}

const ChatSearchBar: React.FC<ChatSearchBarProps> = ({
	searchQuery,
	setSearchQuery,
	handleSearch,
	handleNextMatch,
	handlePreviousMatch,
	closeSearchBar,
}) => (
	<div className='flex justify-between items-center px-3 h-16 border-b border-[#222225]'>
		<Button onClick={closeSearchBar}>
			<ArrowLeft className='text-[#929093] cursor-pointer' size={26} />
		</Button>
		<div className='my-auto mx-2 text-white text-base flex-grow'>
			<Input
				type='text'
				value={searchQuery}
				onChange={(e) => setSearchQuery(e.target.value)}
				onKeyDown={(e) => e.key === 'Enter' && handleSearch()}
				className='flex-1 bg-secondary h-10 py-2 px-4 text-white border-none rounded-2xl'
				placeholder='Search'
			/>
		</div>
		<div className='flex justify-center items-center'>
			<Button onClick={handlePreviousMatch} className='bg-primary text-[#929093] px-1 py-2 rounded-md'>
				<ChevronUp size={26} />
			</Button>
			<Button onClick={handleNextMatch} className='bg-primary text-[#929093] px-1 py-2 rounded-md'>
				<ChevronDown size={26} />
			</Button>
		</div>
	</div>
)

export default ChatSearchBar
