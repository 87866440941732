import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import { SERVER_URL } from '@/env-vars'
import { Store } from '@/store'
import { useAuthFromStorage } from '@/hooks/useAuthStorage'
import { useSyncChat } from '@/mutations/useSyncChat'
import { useChatIdFromUrl } from '@/hooks/useChatIdFromUrl'
import { VideoRecommendationsMessage } from '@/store/types'
import { toast } from 'sonner'
import * as Sentry from '@sentry/react'
import { errors } from '@/i18n/errors'

export function useVideoRecommendations() {
	const { chatId } = useChatIdFromUrl()
	const { authToken } = useAuthFromStorage()
	const syncChat = useSyncChat()

	return useMutation({
		mutationKey: ['video-recommendations', chatId],
		mutationFn: async () => {
			if (!chatId) throw Error('ChatId Not Found')
			const messages = Store.useBoundStore.getState().chats[chatId].messages.slice(-5)
			if (messages.length < 2) {
				return null
			}
			const response = await axios.post(
				`${SERVER_URL}/video/recommendations`,
				{ messages },
				{
					headers: { Authorization: `Bearer ${authToken}` },
				},
			)
			const message = response.data as VideoRecommendationsMessage
			return { message, chatId }
		},
		onSuccess: (data) => {
			if (!data) return
			Store.appendMessagesToChat({ chatId: data.chatId, messages: [data.message] })
			syncChat.mutate()
		},
		onError: (error) => {
			toast(errors.VIDEO_SUGGESTION_ERROR, {
				dismissible: true,
				action: {
					label: 'Ok',
					onClick: () => {},
				},
				duration: 5 * 1000,
			})
			Sentry.captureException(error, {
				tags: {
					type: 'api-call',
					id: 'video-recommendations',
				},
			})
		},
	})
}
