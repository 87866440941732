import {
	AlertDialog,
	AlertDialogAction,
	AlertDialogContent,
	AlertDialogDescription,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle,
} from '@/components/ui/alert-dialog'
import { useLocalStorage } from '@mantine/hooks'
import { useNavigate } from 'react-router-dom'

export default function PrivacyDialog() {
	const [privacyRead, setPrivacyRead] = useLocalStorage({
		key: 'privacy-read',
		defaultValue: false,
		getInitialValueInEffect: false,
	})
	const navigate = useNavigate()

	return (
		<AlertDialog open={!privacyRead}>
			<AlertDialogContent className='bg-primary border-secondary w-5/6 rounded-2xl p-6'>
				<AlertDialogHeader>
					<AlertDialogTitle className='text-primary-foreground text-3xl'>
					</AlertDialogTitle>
					<AlertDialogDescription className='text-primary-foreground space-y-4 text-left text-sm sm:text-lg'>
						<p>
							This app provides access to educational videos relevant to your chosen topics, utilizing
							YouTube’s Data API. By using this app, you understand and accept that certain features rely
							on data and content from YouTube, which are subject to{'  '}
							<a
								href='https://www.youtube.com/t/terms'
								target='_blank'
								className='text-white underline'
							>
								YouTube’s Terms of Service
							</a>{' '}
							and{'  '}
							<a
								href='https://policies.google.com/privacy'
								target='_blank'
								className='text-white underline'
							>
								Google’s Privacy Policy
							</a>. In accordance with these terms, the app may retrieve and display specific information
							made available through YouTube.
						</p>
						<p className='mt-4'>
							For further details on how we handle and protect your data, please refer to our{'  '}
							<a href='/privacy-policy' target='_blank' className='text-white underline'>
								Privacy Policy
							</a>.
						</p>
					</AlertDialogDescription>
				</AlertDialogHeader>
				<AlertDialogFooter className='justify-start sm:justify-start'>
					<AlertDialogAction
						className='my-2 py-4 font-bold bg-gradient-to-l from-[#58FFF1] to-[#70FDB0] text-[#222225] h-12 rounded-[36px] sm:px-16'
						onClick={() => setPrivacyRead(true)}
					>
						Accept
					</AlertDialogAction>
				</AlertDialogFooter>
			</AlertDialogContent>
		</AlertDialog>
	)
}
