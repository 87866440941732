import { useQuery } from '@tanstack/react-query'
import MarkdownIt from 'markdown-it'
import { Button } from '@/components/ui/button'

const md = new MarkdownIt({ html: true })

export default function MarkdownDefault({ path }: { path: string }) {
	const markdown = useQuery({
		queryKey: ['markdown', path],
		queryFn: () => fetch(path).then(r => r.text()),
	})

	console.log({ data: markdown.data })

	return (
		<div className='bg-primary text-primary-foreground p-8 h-dvh overflow-y-scroll'>
			<a href='/'>
				<Button variant='destructive' className='mb-8'>
					Back To Chats
				</Button>
			</a>
			<div
				className='max-w-[90vw] prose'
				dangerouslySetInnerHTML={{ __html: md.render(markdown.data || '') }}
			/>
		</div>
	)
}
