import { Store } from '@/store'
import { type ChangeEventHandler, useEffect, useRef, useState } from 'react'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import {
	ArrowDown,
	ArrowLeft,
	CameraIcon,
	ChevronDown,
	ChevronUp,
	MenuIcon,
	PaperclipIcon,
	Plus,
	Search,
	Square,
} from 'lucide-react'
import Spinner from '@/components/Spinner'
import ImageCropperView from '@/components/ImageCropperDialog'
import { useMounted } from '@/hooks/useMounted'
import { useAuthFromStorage } from '@/hooks/useAuthStorage'
import { useChat } from '@/hooks/useChat'
import { useChatIdFromUrl } from '@/hooks/useChatIdFromUrl'
import ChatMenu from '@/components/ChatMenu'
import TextMessage from '@/components/TextMessage'
import ImageMessage from '@/components/ImageMessage'
import VideoSuggestionMessage from '@/components/VideoSuggestionMessage'
import QuizMessage from '@/components/QuizMessage'
import FollowupMessage from '@/components/FollowUpMessage'
import { useVideoRecommendations } from '@/mutations/useVideoRecommendations'
import VideoRecommendationMessage from './VideoRecommendationMessage'
import useAutoScroll from '@/hooks/useAutoScroll'
import useIsBottomVisible from '@/hooks/useIsBottomVisible'
import AssistantMessage from '@/components/AssistantMessage'
import MarkdownRenderer from '@/components/MarkdownRenderer'
import OnboardingSteps from '@/components/OnboardingSteps'
import { VideoRecommendationsMessage } from '@/store/types'
import { useChatSearch } from '@/hooks/useChatSearch'
import ChatSearchBar from './ChatSearchBar'

/**
 * Returns true if a video message incluses a video link
 */
const messageIncludesVideoLink = (videoLink: string | undefined, message: VideoRecommendationsMessage) => {
	if (!videoLink) return false
	const { short, medium, long } = message.content
	const videos = [short, medium, long].flat()
	const flag = videos.some(video => video.videoLink === videoLink)
	return flag
}

// dprint-ignore
const IntroMessage = "**Welcome to TutorCat!** Your guide for every curious question and academic challenge. Write essays, solve math problems or ask any other question. Let's Explore."

const ChatView = () => {
	const {
		input,
		setInput,
		chatTitle,
		category,
		messages,
		handleSubmit,
		lockChatSubmit,
		handleImageCropDone,
		handleAbortStream,
	} = useChat()
	const messageRef = useRef<string>('')
	const { chatId, setChatId } = useChatIdFromUrl()
	const { authToken, setAuthToken } = useAuthFromStorage()
	const attachmentRef = useRef<HTMLInputElement>(null)
	// used for autoscroll implmentation
	const chatContainerRef = useRef<HTMLDivElement>(null)
	const cameraRef = useRef<HTMLInputElement>(null)
	const canvasRef = useRef<HTMLCanvasElement>(null)
	const textInputRef = useRef<HTMLInputElement>(null)
	const mounted = useMounted()
	const isBottomVisible = useIsBottomVisible(chatContainerRef)
	const {
		searchQuery,
		setSearchQuery,
		handleSearch,
		handleNextMatch,
		handlePreviousMatch,
		messageRefs,
		setSearchResults,
		setCurrentMatchIndex,
		searchResults,
		currentMatchIndex,
	} = useChatSearch(messages)
	const [isSearchBarOpen, setIsSearchBarOpen] = useState(false)

	// This link is used to identify which video message to highlight in the chat
	const referenceVideoLink = Store.useBoundStore(s => s.referenceVideoLink)

	// state to track the index of the currently highlighted message
	// used for highlighting the message containing the `referenceVideoLink`
	const [highlightedMessageIndex, setHighlightedMessageIndex] = useState<number | null>(null)

	// Resets the `referenceVideoLink` in the store and clears the highlighted message index so that the message is no longer highlighted.
	const resetReferenceVideoLink = () => {
		setTimeout(() => {
			Store.setState((draft) => {
				draft.referenceVideoLink = undefined
			})
			setHighlightedMessageIndex(null)
		}, 2000)
	}

	/*
	 Effect to determine which message should be highlighted based on the `referenceVideoLink`.
	 - Searches for the first message in the chat where the `referenceVideoLink` matches a video in that message.
	 - If a matching message is found:
	     - Sets the `highlightedMessageIndex` to its index.
	     - Calls `resetReferenceVideoLink` to clear the highlight after 2 seconds.
	 */
	useEffect(() => {
		if (referenceVideoLink) {
			const messageIndex = messages.findIndex((message) =>
				message.type === 'video_recommendations' &&
				messageIncludesVideoLink(referenceVideoLink, message as VideoRecommendationsMessage)
			)

			if (messageIndex !== -1) {
				setHighlightedMessageIndex(messageIndex)
				resetReferenceVideoLink()
			}
		}
	}, [referenceVideoLink, messages])

	const targetRef = useRef<HTMLDivElement>(null)

	const videoRecommendationsMutation = useVideoRecommendations()

	const lockUserInput = lockChatSubmit || videoRecommendationsMutation.isPending

	const { scrollToBottom } = useAutoScroll({
		streaming: lockUserInput,
		targetRef: chatContainerRef,
	})

	useEffect(() => {
		const top = targetRef.current?.getBoundingClientRect().top
		if (top) {
			console.debug(`scrolling to top ${top}`)
			chatContainerRef.current?.scrollTo({ top, behavior: 'smooth' })
		}
	}, [targetRef.current])

	useEffect(() => {
		messageRef.current = input
	}, [input])

	// handle file upload
	const handleFileUpload: ChangeEventHandler<HTMLInputElement> = async (e) => {
		if (e.target.files && e.target.files.length > 0) {
			const file = e.target.files[0]
			const objectUrl = URL.createObjectURL(file)
			const img = new Image()

			img.onload = () => {
				const canvas = canvasRef.current
				if (!canvas) {
					URL.revokeObjectURL(objectUrl)
					return
				}
				const ctx = canvas.getContext('2d')
				canvas.width = img.width
				canvas.height = img.height
				ctx?.drawImage(img, 0, 0, img.width, img.height)

				const resizedDataUrl = canvas.toDataURL('image/jpeg')

				// Update your state with the data URL
				Store.setState((draft) => {
					draft.imageCropView = {
						imageDataUrl: resizedDataUrl || '',
						isDialogOpen: true,
					}
				})

				// Revoke the object URL to free up memory
				URL.revokeObjectURL(objectUrl)
			}

			img.onerror = (error) => {
				// Handle errors and revoke the object URL
				console.error('Error loading image:', error)
				URL.revokeObjectURL(objectUrl)
			}

			img.src = objectUrl
		}
	}

	const debugPrintChat = () => {
		console.debug({ chatId, chatTitle, messages })
	}

	/**
	 * Show Video Suggestions if
	 * 1. If the Last Message is of type assistant
	 * 2. The user is not currently streaming
	 * 3. Last Message is a `video_suggestion` or a `video_recommendations`
	 */
	const showVideoSuggestion = !lockChatSubmit && messages?.at(-1)?.role === 'assistant' &&
		messages?.at(-1)?.type != 'video_suggestion' &&
		messages?.at(-1)?.type != 'video_recommendations'

	const handleVideoRecommendations = async () => {
		await videoRecommendationsMutation.mutateAsync()
		setTimeout(() => {
			scrollToBottom()
		}, 100)
	}

	const handleNewChat = () => {
		if (messages && messages.length > 0) {
			setChatId()
		}
	}

	if (!mounted) {
		return null
	}

	if (!authToken) {
		return null
	}

	return (
		<>
			<OnboardingSteps />
			<div className='flex flex-col h-svh bg-primary text-primary font-inter'>
				<canvas className='hidden' ref={canvasRef} />
				<ImageCropperView handleCropDone={handleImageCropDone} />
				{isSearchBarOpen
					? (
						<ChatSearchBar
							searchQuery={searchQuery}
							setSearchQuery={setSearchQuery}
							handleSearch={handleSearch}
							handleNextMatch={handleNextMatch}
							handlePreviousMatch={handlePreviousMatch}
							closeSearchBar={() => {
								setIsSearchBarOpen(false)
								setSearchQuery('')
								setSearchResults([])
								setCurrentMatchIndex(null)
							}}
						/>
					)
					: (
						<div className='flex gap-4 px-4 justify-between w-screen h-16 border-b border-solid border-[#222225]'>
							<div className='flex justify-center items-center px-1'>
								<MenuIcon
									color='#929093'
									className='text-white cursor-pointer history_step'
									onClick={() =>
										Store.setState((draft) => {
											draft.isSidebarOpen = !draft.isSidebarOpen
										})}
								/>
							</div>
							<div
								className='my-auto mx-auto w-auto text-center text-white text-base flex-grow'
								onClick={debugPrintChat}
							>
								<div className='flex justify-center'>
									<p className='absolute left-1/2 transform -translate-x-1/2 -translate-y-3 text-[#FFFEFF] text-md'>
										TutorCat
									</p>
								</div>
							</div>
							<div className='flex items-center space-x-1'>
								<div className='flex gap-4 justify-center items-center new_chat_step'>
									<Button disabled={messages.length === 0} className='px-0'>
										<Search
											className=' border-[#929093] text-[#929093] cursor-pointer'
											size={22}
											onClick={() => setIsSearchBarOpen(!isSearchBarOpen)}
										/>
									</Button>
									<Button disabled={messages.length === 0} className='px-0'>
										<Plus
											className='rounded-full border-2 border-solid border-[#929093] text-[#929093] cursor-pointer'
											size={22}
											onClick={handleNewChat}
										/>
									</Button>
									<Button disabled={messages.length === 0} className='px-0'>
										<ChatMenu />
									</Button>
								</div>
							</div>
						</div>
					)}
				<div className='flex-1 overflow-y-auto relative' ref={chatContainerRef}>
					<div className='max-w-[800px] p-3 mx-auto'>
						{messages?.length == 0 && (
							<div>
								<div className='mt-32 mb-32 text-white text-left'>
									<div className='inline-block rounded-lg min-w-64 max-w-[80vw] text-left text-primary-foreground'>
										<AssistantMessage>
											<MarkdownRenderer content={IntroMessage} />
										</AssistantMessage>
									</div>
								</div>
								<div className='flex justify-center px-auto'>
									<div className='flex gap-8 text-[#F5F5F5] mx-6'>
										<div
											onClick={() => cameraRef.current?.click()}
											className='rounded-2xl bg-[#232125] border-[#302E31] border-solid border flex flex-col gap-3 p-5 cursor-pointer'
										>
											<img alt='' src='/camera.svg' className='w-[32px] h-[28px]' />
											<p>Snap to solve any question</p>
										</div>
										<div
											onClick={() => textInputRef.current?.focus()}
											className='rounded-2xl bg-[#232125] border-[#302E31] border-solid border flex flex-col gap-3 p-5 cursor-pointer'
										>
											<img alt='' src='/keyboard.svg' className='w-[37px] h-[21px] mb-[7px]' />
											<p>Type to solve any question</p>
										</div>
									</div>
								</div>
							</div>
						)}
						{messages?.map((message, index) => (
							<div
								key={index}
								ref={(el) => {
									// dynamically populate the messageRefs array with DOM elements
									if (el) {
										messageRefs.current[index] = el
									}
								}}
								className={`mb-4 text-white ${message.role === 'user' ? 'text-right' : 'text-left'} ${
									index === highlightedMessageIndex
										? 'bg-[#37CD8E]/20 rounded-lg transition-opacity ease-in-out'
										: ''
								} ${
									currentMatchIndex !== null && searchResults[currentMatchIndex] === index
										? 'bg-[#37CD8E]/20 rounded-lg transition-opacity ease-in-out'
										: ''
								}`}
							>
								{message.type == 'text' && <TextMessage message={message} />}
								{message.type == 'image_url' && <ImageMessage message={message} />}
								{message.type == 'video_suggestion' && <VideoSuggestionMessage message={message} />}
								{message.type == 'quiz' && <QuizMessage index={index} message={message} />}
								{message.type == 'followup_suggestions' && messages.length - 1 === index && (
									<FollowupMessage
										message={message}
										handleSelect={message => handleSubmit(message)}
									/>
								)}
								{message.type == 'video_recommendations' && (
									<div
										ref={messageIncludesVideoLink(referenceVideoLink, message)
											? targetRef
											: undefined}
									>
										<VideoRecommendationMessage
											message={message}
										/>
									</div>
								)}
							</div>
						))}
						{showVideoSuggestion && (
							<div className='flex justify-end mt-6'>
								{/* The Designer Wanted a Gradient In Border and CSS has no API for it */}
								{/* Good luck understanding it. We have to nest multiple divs */}
								<div className='z-10 bg-gradient-to-r from-[#1CF0FA] to-[#ADFF69] p-[1px] rounded-3xl'>
									<div className='bg-primary rounded-3xl'>
										<Button
											onClick={handleVideoRecommendations}
											disabled={videoRecommendationsMutation.isPending}
											className='flex gap-4 text-left py-2 px-6 bg-green-700 bg-opacity-10 rounded-3xl text-primary-foreground cursor-pointer'
										>
											<span>
												Suggest me video tutorials
											</span>
										</Button>
									</div>
								</div>
							</div>
						)}
						{messages.at(-1)?.type === 'video_recommendations' && (
							<div className='flex justify-end mt-4'>
								<div className='z-10 bg-gradient-to-r from-[#1CF0FA] to-[#ADFF69] p-[1px] rounded-3xl'>
									<div className='bg-primary rounded-3xl'>
										<div
											onClick={handleNewChat}
											className='flex gap-4 text-left py-2 px-6 bg-green-700 bg-opacity-10 rounded-3xl text-primary-foreground cursor-pointer'
										>
											<span>
												Try another subject
											</span>
											<img alt='' src='/book-open.svg' />
										</div>
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
				<div className='px-2 flex pt-4 pb-[38px] border-t border-solid border-[#222225]'>
					{!isBottomVisible && false && (
						<button
							onClick={scrollToBottom}
							className='absolute bottom-32 left-1/2 transform -translate-x-1/2 text-white px-2 py-2 rounded-full z-50'
						>
							<img src='/scroll-down.svg' />
						</button>
					)}
					<input
						onChange={handleFileUpload}
						type='file'
						className='hidden'
						accept='image/*'
						ref={attachmentRef}
					/>
					<input
						onChange={handleFileUpload}
						type='file'
						className='hidden'
						accept='image/*'
						ref={cameraRef}
						capture='environment'
					/>
					<Button onClick={() => attachmentRef.current?.click()} className='px-2'>
						<PaperclipIcon className='file_step text-white mt-2' color='#929093' />
					</Button>
					<Button onClick={() => cameraRef.current?.click()} className='px-2'>
						<CameraIcon className='camera_step text-white mt-2' color='#929093' />
					</Button>
					<Input
						type='text'
						value={input}
						onChange={(e) => setInput(e.target.value)}
						className='flex-1 bg-secondary h-12 py-2 px-4 text-white border-none rounded-3xl'
						placeholder='Ask a Question...'
						ref={textInputRef}
					/>
					{/* Disable The Send Button When Streaming */}
					<Button
						disabled={videoRecommendationsMutation.isPending}
						className='ml-2 p-2 w-12 h-12 rounded-3xl bg-[#249F7E] cursor-pointer disabled:opacity-100 hover:bg-[#249F7E]'
						onClick={lockUserInput ? () => handleAbortStream() : () => handleSubmit(input)}
					>
						{videoRecommendationsMutation.isPending
							? <Spinner />
							: (lockUserInput
								? <Square color='white' fill='white' className='w-5 h-5' />
								: <img alt='' src='/message-send.svg' className='rotate-[22] pt-1' />)}
					</Button>
				</div>
			</div>
		</>
	)
}

export default ChatView
