import { useLocalStorage } from '@mantine/hooks'
import { forwardRef, useEffect, useState } from 'react'
import Joyride, { CallBackProps, TooltipRenderProps } from 'react-joyride'

const WALKTHROUGH_OVERRIDE = false

/**
 * Right now the content is hardcoded inside the SVG Vectors I am using
 * so `content` is not really picked up from here
 */

const steps = [
	{
		target: '.camera_step',
		content: 'Scan and upload any question',
		disableBeacon: true,
	},
	{
		target: '.new_chat_step',
		content: 'Explore chat history anytime.',
		disableBeacon: true,
	},
	{
		target: '.history_step',
		content: 'Start new thread for any subject',
		disableBeacon: true,
	},
]

const CustomTooltipComponent = forwardRef<HTMLDivElement, TooltipRenderProps>((props, ref) => {
	console.log(props)
	if (props.index === 0) {
		return (
			<div ref={ref} {...props} className='relative'>
				<div className='relative left-5' {...props.primaryProps}>
					<img src='/callout-bottom-left.svg' />
				</div>
			</div>
		)
	}

	if (props.index === 1) {
		return (
			<div ref={ref} {...props} className='relative'>
				<div className='relative right-9' {...props.primaryProps}>
					<img src='/callout-right-up.svg' className='w-full h-auto' />
				</div>
			</div>
		)
	}

	return (
		<div ref={ref} {...props} className='relative'>
			<div className='relative left-8' {...props.primaryProps}>
				<img src='/callout-left-up.svg' />
			</div>
		</div>
	)
})

export default function OnboardingSteps() {
	const [privacyRead] = useLocalStorage<boolean>({
		key: 'privacy-read',
		getInitialValueInEffect: false,
	})

	const [onboardingComplete, setOnboardingComplete] = useLocalStorage<boolean>({
		key: 'onboarding-complete',
		defaultValue: false,
		getInitialValueInEffect: false,
	})

	const override = window.location.host.includes('localhost') && WALKTHROUGH_OVERRIDE

	const handleJoyrideCallback = (data: CallBackProps) => {
		if (data.index === 0 && data.action === 'reset') {
			return setOnboardingComplete(true)
		}
		if (data.action == 'close' || data.action == 'skip') {
			return setOnboardingComplete(true)
		}
	}

	return (
		<Joyride
			steps={steps}
			run={override || (privacyRead && !onboardingComplete)}
			continuous={true}
			callback={handleJoyrideCallback}
			disableOverlayClose={true}
			spotlightPadding={0}
			hideCloseButton={true}
			styles={{
				options: {
					arrowColor: 'transparent',
					overlayColor: 'rgba(0, 0, 0, 0.85)',
					backgroundColor: 'white',
					primaryColor: 'black',
					textColor: 'black',
					zIndex: 1000,
				},
			}}
		/>
	)
}
