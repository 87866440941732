import { VideoRecommendationsMessage, type VideoSuggestionMessage, type YoutubeVideoDetails } from '@/store/types'
import AssistantMessage from '@/components/AssistantMessage'
import { useNavigate } from 'react-router-dom'
import { Store } from '@/store'
import { ArrowRightIcon } from 'lucide-react'

export default function VideoRecommendationMessage({ message }: { message: VideoRecommendationsMessage }) {
	const navigate = useNavigate()

	const handleClick = (video: YoutubeVideoDetails) => {
		Store.setState(draft => {
			draft.videoRecommendations = {
				currentVideo: video,
				currentMessage: message,
			}
			draft.referenceVideoLink = video.videoLink
		})
		navigate('/video')
	}

	// For Displaying, pick first from each of the three duration groups
	const { short, medium, long } = message.content
	const videos = [short[0], medium[0], long[0]]

	return (
		<AssistantMessage>
			<div className='flex flex-col gap-2 bg-[#212121] w-5/6 rounded-2xl px-2 py-4'>
				<h2 className='mb-2 ml-2 text-sm font-semibold'>SUGGESTED VIDEOS:</h2>
				<div className='grid grid-cols-1 gap-2'>
					{videos.map((video, idx) => (
						<div
							key={idx}
							className='bg-[#2C2C2C] p-2 rounded-xl w-full cursor-pointer flex gap-3 items-center'
							onClick={() => handleClick(video)}
						>
							<div className='relative w-[136px] h-[78px] flex-shrink-0'>
								<img
									src={video.thumbnailLink}
									alt='thumbnail'
									className='rounded-lg object-cover w-full h-full'
								/>
								{video.duration && (
									<div className='text-sm text-white absolute bottom-2 right-2 bg-black p-0.5 rounded-lg bg-opacity-50'>
										{video.duration}
									</div>
								)}
							</div>
							<div className='text-white text-xs flex items-center'>
								<p className='line-clamp-3'>{video.title}</p>
							</div>
						</div>
					))}
				</div>
				<div className='flex justify-end'>
					{videos.length !== 0 && (
								<div className='flex justify-center items-center mt-4 mb-2 mr-[52px]'>
									<div
										onClick={() => handleClick(videos[0])}
										className='absolute text-left px-2 text-primary-foreground cursor-pointer'
									>
										<img src='explore-more.svg'/>
									</div>
								</div>
					)}
				</div>
			</div>
		</AssistantMessage>
	)
}
