import { useMutation } from '@tanstack/react-query'
import { SERVER_URL } from '@/env-vars'
import { Store } from '@/store'
import { useAuthFromStorage } from '@/hooks/useAuthStorage'
import { useSyncChat } from '@/mutations/useSyncChat'
import { Stream } from 'openai/streaming'
import { toast } from 'sonner'
import * as Sentry from '@sentry/react'
import { errors } from '@/i18n/errors'

export function useGptStream() {
	const { authToken } = useAuthFromStorage()
	const syncChat = useSyncChat()

	return useMutation({
		mutationKey: ['gpt-stream'],
		retry: false,
		mutationFn: async ({ chatId, abortController }: { chatId: string; abortController: AbortController }) => {
			const messages = Store.useBoundStore.getState().chats[chatId].messages
			const lastMessages = messages.slice(-6)
			const timeout = 5000 // Timeout in milliseconds
			let receivedToken = false

			const indexLastMessage = messages.length - 1

			const response = await fetch(`${SERVER_URL}/chat/run`, {
				method: 'POST',
				headers: {
					Authorization: `Bearer ${authToken}`,
					'Content-Type': 'application/json',
					Connection: 'keep-alive',
				},
				body: JSON.stringify({
					chatId: chatId,
					messages: lastMessages,
				}),
				signal: abortController.signal,
			})

			// check for rate limit error
			if (response.status === 429) {
				Sentry.captureEvent({
					message: 'GPT API Rate Limit Hit',
				})
				toast('Please Try Again Tommorow', {
					description: 'Your Daily Rate Limit has Been Reached',
					dismissible: true,
					duration: 5 * 1000,
					action: {
						label: 'OK',
						onClick: () => {},
					},
				})
				return { success: false }
			}

			if (response.status > 300) {
				throw Error(response.status + ':' + response.statusText)
			}

			const timeoutId = setTimeout(() => {
				if (!receivedToken) {
					abortController.abort()
					clearTimeout(timeoutId)
					throw new Error('Timeout: No token received')
				}
			}, timeout)

			const stream = Stream.fromSSEResponse<{ token: string }>(response, abortController)

			for await (const chunk of stream) {
				if (chunk && chunk.token) {
					receivedToken = true
					Store.updateChatMessage({ chatId, content: chunk.token, messageIdx: indexLastMessage })
				}
			}

			clearTimeout(timeoutId)
			return { success: true }
		},
		onSuccess: ({ success }) => {
			success && syncChat.mutate()
		},
		onError: (error, { chatId }) => {
			const chat = Store.useBoundStore.getState().chats[chatId]
			toast(errors.SERVER_ERROR, {
				dismissible: true,
				action: {
					label: 'Ok',
					onClick: () => {},
				},
				duration: 5 * 1000,
			})
			Sentry.captureException(error, {
				tags: {
					type: 'api-call',
					id: 'gpt-stream',
					numMessages: chat.messages.length,
				},
			})
		},
	})
}
