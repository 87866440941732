import { useListVideos } from '@/hooks/useListVideos'
import { useRemoveVideo } from '@/mutations/useRemoveVideo'
import { useNavigate } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import { ArrowLeft, MoreVertical, Search } from 'lucide-react'
import { Store } from '@/store'
import { useEffect, useState } from 'react'
import { SavedVideo } from '@/store/types'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@/components/ui/dropdown-menu'
import { useLocalStorage } from '@mantine/hooks'

function formatDate(dateString: string) {
	const date = new Date(dateString)
	const today = new Date()
	const yesterday = new Date(today)
	yesterday.setDate(today.getDate() - 1)

	if (
		date.getDate() === today.getDate() &&
		date.getMonth() === today.getMonth() &&
		date.getFullYear() === today.getFullYear()
	) {
		return 'today'
	} else if (
		date.getDate() === yesterday.getDate() &&
		date.getMonth() === yesterday.getMonth() &&
		date.getFullYear() === yesterday.getFullYear()
	) {
		return 'yesterday'
	} else {
		return date.toLocaleDateString()
	}
}

export default function SavedVideosPage() {
	const { data: savedVideos } = useListVideos()
	const removeFromSavedVideos = useRemoveVideo()
	const navigate = useNavigate()
	const queryClient = useQueryClient()
	const [searchTerm, setSearchTerm] = useState('')
	const [hasNewSavedVideos, setHasNewSavedVideos] = useLocalStorage<boolean>({
		key: 'hasNewSavedVideos',
		defaultValue: false,
	})

	useEffect(() => {
		setHasNewSavedVideos(false)
	}, [])

	const handleVideoClick = (video: SavedVideo) => {
		const currentVideo = {
			title: video.title,
			duration: video.duration,
			videoLink: video.videoLink,
			thumbnailLink: video.thumbnail,
		}

		Store.setState(draft => {
			draft.videoRecommendations = {
				currentVideo: currentVideo,
				currentMessage: {
					role: 'assistant',
					type: 'video_recommendations',
					content: {
						short: video.recommendations.short,
						medium: video.recommendations.medium,
						long: video.recommendations.long,
					},
					metadata: { query: '' },
				},
			}
		})

		navigate('/video')
	}

	const handleGoToChat = (e: any, chatId: string, videoLink: string) => {
		e.stopPropagation()
		Store.setState(draft => {
			draft.referenceVideoLink = videoLink
			draft.chatId = chatId
		})
		navigate('/')
	}

	const handleUnsaveVideo = (e: any, video: SavedVideo) => {
		e.stopPropagation()
		removeFromSavedVideos.mutate(video.title, {
			onSuccess: () => {
				queryClient.invalidateQueries({ queryKey: ['list-saved-videos'] })
			},
		})
	}

	const filteredVideos = savedVideos?.filter((video: SavedVideo) => {
		const searchText = `${video.title} ${video.category || ''}`.toLowerCase()
		return searchText.includes(searchTerm.toLowerCase())
	})

	return (
		<div className='flex flex-col h-screen bg-[#151518]'>
			<div className='flex items-center px-4 h-14'>
				<button onClick={() => navigate(-1)} className='p-2 -ml-2'>
					<ArrowLeft className='w-6 h-6 text-[#929093]' />
				</button>
				<h1 className='flex-1 text-center text-white text-base font-medium'>Saved Videos</h1>
				<div className='w-10' />
			</div>

			<hr className='h-0.5 mb-2 mt-2 bg-[#1F1F1F] border-black' />

			<div className='px-4 py-2 mb-3'>
				<div className='relative'>
					{!searchTerm && (
						<div className='absolute inset-y-0 left-3 flex items-center pointer-events-none'>
							<Search className='text-[#929093] w-5 h-5' />
						</div>
					)}
					<input
						type='text'
						placeholder='      e.g. mathematics, chemistry'
						className='w-full h-12 px-4 bg-[#2C2C2C] rounded-xl text-white placeholder:text-[#929093] focus:outline-none focus:ring-1 focus:ring-zinc-700'
						value={searchTerm}
						onChange={(e) => setSearchTerm(e.target.value)}
					/>
				</div>
			</div>

			{(!savedVideos || savedVideos.length === 0)
				? (
					<div className='flex flex-col items-center justify-center flex-1 p-4 text-center'>
						<p className='text-lg font-medium text-white mb-2'>No saved videos yet</p>
						<p className='text-sm text-zinc-400'>Videos you save will appear here</p>
					</div>
				)
				: (
					<div className='flex-1 px-4 space-y-4 pb-4 overflow-y-auto'>
						{filteredVideos?.map((video: SavedVideo) => (
							<div
								key={video.videoLink}
								className='relative bg-[#2C2C2C] rounded-xl overflow-hidden'
								onClick={() => handleVideoClick(video)}
							>
								<div className='flex p-1.5 gap-2'>
									<div className='relative flex-shrink-0'>
										<img
											src={video.thumbnail}
											alt=''
											className='w-[110px] h-[75px] rounded-lg object-cover'
										/>
										{video.duration && (
											<div className='absolute bottom-1 right-1 px-1 py-0.5 text-[10px] font-medium text-white bg-black/60 rounded'>
												{video.duration}
											</div>
										)}
									</div>

									<div className='flex-1 min-w-0 relative'>
										<h3 className='text-xs font-medium text-[#D9D9D9] line-clamp-2 mt-1.5 max-w-[calc(100%-40px)]'>
											{video.title}
										</h3>
										<div className='absolute bottom-1.5'>
											<p className='text-[10px] text-[#979797] font-semibold'>{video.category}</p>
											<span className='text-[10px] text-[#979797] block'>
												Saved {formatDate(video.savedAt)}
											</span>
										</div>

										<div className='absolute bottom-0.5 right-2'>
											<button
												onClick={(e) => handleGoToChat(e, video.chatId, video.videoLink)}
												className='text-xs font-medium'
											>
												<img src='/go-to-chat.svg' alt='Go to chat' />
											</button>
										</div>
									</div>

									<DropdownMenu>
										<DropdownMenuTrigger className='absolute top-2 right-0.5'>
											<MoreVertical className='w-5 h-5 text-[#929093]' />
										</DropdownMenuTrigger>
										<DropdownMenuContent align='end' className='w-28 bg-[#222222] border-[#222222]'>
											<DropdownMenuItem
												onClick={(e) => handleUnsaveVideo(e, video)}
												className='text-white focus:text-white focus:bg-z[#222222]'
											>
												<img src='/unsave.svg' />
											</DropdownMenuItem>
										</DropdownMenuContent>
									</DropdownMenu>
								</div>
							</div>
						))}
					</div>
				)}
		</div>
	)
}
