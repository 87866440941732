import React from 'react'
import { Card, CardContent } from '@/components/ui/card'
import { YoutubeVideoDetails } from '@/store/types'
import { Store } from '@/store'

const VideoRecommendationCard = (video: YoutubeVideoDetails) => (
	<Card
		className='w-[136px] h-[90px] flex-shrink-0 inline-block border-none rounded-sm bg-transparent'
		onClick={() =>
			Store.setState(draft => {
				if (!draft.videoRecommendations) return
				draft.videoRecommendations.currentVideo = video
			})}
	>
			{/* <img src={video.thumbnailLink} className='w-full h-28 rounded-md mb-2' /> */}
			<div className='relative w-[130px] h-[80px] flex-shrink-0'>
				<img
					src={video.thumbnailLink}
					alt='thumbnail'
					className='rounded-lg object-cover w-full h-full'
				/>
				{video.duration && (
					<div className='text-xs text-white absolute bottom-2 right-2 bg-black p-0.5 rounded-lg bg-opacity-50'>
						{video.duration}
					</div>
				)}
			</div>
			<h3 className='text-xs text-[#D9D9D9] line-clamp-2 mt-1 ml-2 mr-4'>{video.title}</h3>
	</Card>
)

export default VideoRecommendationCard
