import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import { SERVER_URL } from '@/env-vars'
import { Store } from '@/store'
import { Chat } from '@/store/types'
import { useAuthFromStorage } from '@/hooks/useAuthStorage'
import { toast } from 'sonner'
import { errors } from '@/i18n/errors'

export function useRetrieveChat() {
	const { authToken } = useAuthFromStorage()

	return useMutation({
		mutationKey: ['retrieve-chat'],
		mutationFn: async ({ chatId }: { chatId: string }) => {
			const response = await axios.get(`${SERVER_URL}/chat/id/${chatId}`, {
				headers: { Authorization: `Bearer ${authToken}` },
			})

			if (response.status < 400) {
				return response.data as Chat
			} else if (response.status >= 500) {
				throw new Error('Server Error')
			} else if (response.status == 404) {
				return null
			}
		},
		onSuccess: (chat) => {
			if (chat) {
				Store.updateChats({ newChats: [chat], updateMessages: true })
			}
		},
		onError: () => {
			toast(errors.SERVER_ERROR, {
				dismissible: true,
				action: {
					label: 'Ok',
					onClick: () => {},
				},
				duration: 5 * 1000,
			})
		},
	})
}
